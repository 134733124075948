
import { validate } from "@/cms-services/validators";
import { ListDataSource } from "@/data/List/ListDataSource";
import { EventCertificateObjectDataSource } from "@/data/Object/EventCertificateObjectDataSource";
import {mdiDownload, mdiPlus, mdiTrashCan} from "@mdi/js";
import { Vue, Component, Prop } from "vue-property-decorator";
import { EventUserListDataSource } from "@/data/List/UserListDataSoure";
import FileUploaderDialog from "@/components/helpers/file-uploader-dialog.vue";
import FileUploader from "@/components/file-uploader.vue";
import axios from "axios";
@Component({
  components: { FileUploaderDialog, FileUploader },
})
export default class CertificateCard extends Vue {
  @Prop() certificate!: any;
  @Prop() certificateTemplateDataSource!: ListDataSource;
  @Prop() personDataSource!: ListDataSource;
  @Prop() userDataSource!: EventUserListDataSource;
  fileUploaderDialogVisible = false;
  userEmail: string = "";
  dataSource = new EventCertificateObjectDataSource(
    this.certificate.id,
    this.certificate
  );
  certificateLectorDataSource: ListDataSource = new ListDataSource({
    className: "EventCertificateLector",
    config: {},
  });

  certificatePersonDataSource = new ListDataSource({
    className: "EventCertificatePerson",
    config: {},
  });

  icons: any = {
    plus: mdiPlus,
    trash: mdiTrashCan,
    download:mdiDownload
  };
  mask = "";
  definedUser: any = null;
  headers: any = [
    {
      text: "ID",
      value: "id",
      sortable: true,
    },
    {
      text: "ФИО",
      value: "userProfile.fullName",
      sortable: true,
    },
    {
      value: "actions",
      sortable: true,
      align: "right",
    },
  ];
  downloading:boolean = false;
  lectorHeaders: any = [
    {
      text: "ID",
      value: "id",
      sortable: true,
    },
    {
      text: "ФИО",
      value: "fullName",
      sortable: true,
    },
    {
      value: "actions",
      sortable: true,
      align: "right",
    },
  ];
  metaData = {
    caption: {
      name: "caption",
      type: "string",
      description:
        "Заголовок компонента. Обычно используется как основной заголовок при визуализации как страницы, так и виджетов.",
      caption: "Подпись",
      editor: "string",
      priority: 100,
      group: "general",
      visibleInTable: true,
      validations: [
        {
          validator: "max",
          value: 250,
          errorMessage: "Поле не может содержать больше чем 250 символов",
        },
        {
          validator: "required",
          value: true,
          errorMessage: "Поле не может быть пустым",
        },
      ],
      config: {},
    },
    caption2: {
      name: "caption2",
      type: "string",
      description:
        "Заголовок компонента. Обычно используется как основной заголовок при визуализации как страницы, так и виджетов.",
      caption: "Подпись",
      editor: "string",
      priority: 100,
      group: "general",
      visibleInTable: true,
      validations: [
        {
          validator: "max",
          value: 250,
          errorMessage: "Поле не может содержать больше чем 250 символов",
        },
        {
          validator: "required",
          value: true,
          errorMessage: "Поле не может быть пустым",
        },
      ],
      config: {},
    },
    description: {
      name: "description",
      type: "string",
      description:
        "Короткое описание компонента, которое обычно используется для визуализации описательной части в виджетах, метаданных. Иногда используется в качестве описательного блока под заголовком в основной визуализации.",
      caption: "Описание",
      editor: "memo",
      priority: 100,
      group: "general",
      visibleInTable: false,
      validations: [],
      config: {},
    },
    date: {
      name: "date",
      caption: "Дата",
      editor: "date",
      validations: [],
      config: {},
    },
    country: {
      name: "countryId",
      type: "string",
      description: null,
      caption: "Страна",
      editor: "countryselector",
      priority: 100,
      group: "general",
      visibleInTable: false,
      validations: [],
      config: {},
    },
    city: {
      name: "cityId",
      type: "string",
      description: null,
      caption: "Город",
      editor: "cityselector",
      priority: 100,
      group: "general",
      visibleInTable: false,
      validations: [],
      config: {},
    },
  };
  $message: any;
  errorMessages: any[] = new Array<any>();
  $confirm: any;
  fileType: string = "";
  lectorId: any = null;
  loaded: boolean = false;

  get filteredPersons() {
    return this.personDataSource.items.filter(
      (p) =>
        this.dataSource.model.certificateLectors.find((cp) => cp.id == p.id) ==
        undefined
    );
  }

  get errorCount() {
    return this.errorMessages?.length ?? 0;
  }

  async importPersons(){
    await axios.post(`/manage/EventCertificate/${this.certificate.id}/import/`);
    await this.certificatePersonDataSource.get()
  }

  async downloadFile(){

      this.fetchFile(`/user/certificates/${this.certificate.id}/`)
  }

  fetchFile(url) {
    this.downloading = true;
    axios({
      url: url, //your url
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', this.certificate.caption + " " +(this.certificate.caption2??"") + ".pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }).catch( async(e)=>{
      const err = await e.response.data.text()
      this.$message(err, "error")
    }).finally(()=>{
      this.downloading = false;
    });
  }

  async uploadFile(event: any) {
    try {
      this.fileType == "stamp"
        ? await this.dataSource.uploadStamp(event)
        : await this.dataSource.uploadSignature(event);
      const fileName = this.fileType == "stamp" ? "Печать" : "Подпись";
      this.$message(fileName + " успешно загружен");
      this.fileUploaderDialogVisible = false;
    } catch (error) {
      this.$message(error);
    }
  }

  async uploadlisteners(event: any) {
    try {
      await this.dataSource.uploadListenersCsv(event);
      this.certificatePersonDataSource.items = this.dataSource.model.persons;
      this.$message("Слушатели успешно загружены");
    } catch (error) {
      this.$message(error);
    }
  }

  openUploadDilog(fileType: string) {
    this.fileType = fileType;
    this.fileUploaderDialogVisible = true;
  }

  async changeInstanceState() {
    try {
      const instanceStateId = this.dataSource.model.instanceStateId;
      await this.dataSource.updateField({
        fieldName: "instanceStateId",
        fieldValue: instanceStateId == 4 ? 2 : 4,
      });
      this.$message("Статус успешно изменён");
    } catch (error: any) {
      this.$message(error?.response?.data, "error");
    }
  }

  async findByEmail() {
    this.errorMessages = await this.validate();

    if (this.errorMessages.length > 0) return;

    try {
      this.definedUser = await this.userDataSource.findUser(
        "email",
        this.userEmail
      );
    } catch (error: any) {
      this.errorMessages = error?.response?.data;
    }
  }

  async validate() {
    const validations = [
      {
        validator: "required",
        value: true as any,
        errorMessage: "Поле не может быть пустым",
      },
    ];
    if (
      !this.userEmail.includes("@") &&
      (this.userEmail.startsWith("+") ||
        this.userEmail.startsWith("8") ||
        this.userEmail.startsWith("9"))
    ) {
      this.mask = "+7(###) ###-##-##";
      validations.push({
        validator: "min",
        errorMessage: "Пожалуйста введите валидный номер телефона",
        value: 15,
      });
    } else {
      this.mask = "";
      validations.push({
        validator: "regular",
        value: "email",
        errorMessage: "Введите корректный адрес",
      });
    }
    const { errorMessages } = await validate(
      { name: "userEmail", validations },
      { userEmail: this.userEmail }
    );
    return errorMessages ?? [];
  }

  async validateWithoutErrors() {
    const errorMessages: any = await this.validate();
    if (errorMessages.length > 0) this.errorMessages = [];
  }

  async created() {
    this.certificatePersonDataSource.config.filter = JSON.stringify([
      {
        fieldName:"eventCertificateId",
        fieldValue:this.certificate?.id
      }
    ]);


    await Promise.all([
      this.dataSource.loadSignature(),
      this.dataSource.loadStamp(),
      this.certificatePersonDataSource.get()
    ]);
    this.certificatePersonDataSource.items = this.certificate?.persons;
    this.certificateLectorDataSource.items = this.dataSource.model.lectors;
    this.loaded = true;
  }

  async addCertificatePerson() {
    try {
      await this.certificatePersonDataSource.add({
        userProfileId: this.definedUser.id,
        eventCertificateId: this.dataSource.model.id,
      });
      this.$message("Слушатель успешно добавлен");
      this.definedUser = null;
      this.userEmail = "";
    } catch (error: any) {
      this.$message(error?.response?.data, "error");
    }
  }

  async changeLector(event) {
    try {
      await this.certificateLectorDataSource.add({
        personId: event,
        certificateId: this.certificate.id,
      });
      await this.dataSource.get();
      this.lectorId = null;
      this.$message("Лектор успешно обновлён");
    } catch (error: any) {
      this.$message(error?.response?.data, "error");
    }
  }

  async changeTemplate() {
    try {
      await this.dataSource.updateField({
        fieldName: "certificateTemplateId",
        fieldValue: this.dataSource.model.certificateTemplateId,
      });
      this.$message("Шаблон успешно обновлён");
    } catch (error: any) {
      this.$message(error?.response?.data, "error");
    }
  }

  async removeCertificatePerson(id) {
    const confirm = await this.$confirm(
      "Вы действительно хотите удалить данного слушателя ?"
    );
    if (!confirm) return;
    try {
      await this.certificatePersonDataSource.remove(id);
      this.$message("Слушатель успешно  удфлён");
    } catch (error: any) {
      this.$message(error?.response?.data, "error");
    }
  }

  async removeCertificateLector(id) {
    const confirm = await this.$confirm(
      "Вы действительно хотите удалить данного Лектора ?"
    );
    if (!confirm) return;
    try {
      const certificateLector = this.dataSource.model.lectors.find(
        (l) => l.person.id == id
      );

      if (!certificateLector) throw new Error("");

      await this.certificateLectorDataSource.remove(certificateLector.id);
      await this.dataSource.get();
      this.$message("Лектор успешно  удалён");
    } catch (error: any) {
      this.$message(error?.response?.data, "error");
    }
  }
}
