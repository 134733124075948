
import { ListDataSource } from "@/data/List/ListDataSource";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import { mdiPlus, mdiTrashCan } from "@mdi/js";
import { Vue, Component, Ref } from "vue-property-decorator";
import DataEditorDialog from "@/components/helpers/data/editor-dialog.vue";
import CertificateCard from "@/pages/manage/events/components/cetificate-card.vue";
import { EventUserListDataSource } from "@/data/List/UserListDataSoure";
import { Filter } from "@/cms-services/filter";
@Component({
  components: { DataEditorDialog, CertificateCard },
})
export default class Certificates extends Vue {
  @Ref("dataEditorDialog") dataEditorDialog!: DataEditorDialog;
  loaded: boolean = false;
  eventObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.eventId,
    config: {},
    className: "event",
  });

  userDataSource = new EventUserListDataSource({
    pageIndex: 1,
    pageSize: 100,
  });



  dataSource = new ListDataSource({
    className: "EventCertificate",
    config: {
      pageIndex: 1,
      pageSize: 100,
      filter: JSON.stringify([
        new Filter("EventId", +this.$route.params.eventId),
      ]),
    },
  });

  personDataSource = new ListDataSource({
    className: "Person",
    config: {
      pageIndex: 1,
      pageSize: 100,
    },
  });

  certificateTemplateDataSource = new ListDataSource({
    className: "CertificateTemplate",
    config: {
      pageIndex: 1,
      pageSize: 100,
    },
  });

  model: any = {
    caption: "",
    lectorId: null,
    certificateTemplateId: null,
    eventId: +this.$route.params.eventId,
  };

  actions: any = {
    getLectors: () => this.personDataSource.items,
    getTemplates: () => this.certificateTemplateDataSource.items,
  };

  fields: any = [
    {
      editor: "string",
      attrs: {
        type: "text",
        outlined: true,
        label: "Заголовок *",
      },
      name: "caption",
      validations: [
        {
          validator: "required",
          errorMessage: "Поле не может быть пустым",
          value: true,
        },
      ],
    },
    {
      name: "lectorId",
      editor: "enum",
      attrs: {
        type: "text",
        outlined: true,
        label: "Выберите лектора",
      },
      config: {
        getItems: "getLectors",
        valueKeyName: "id",
        labelKeyName: "caption",
        type: "select",
        noDataText: "Данные не найдены",
      },
      validations: [
        {
          validator: "required",
          errorMessage: "Поле не может быть пустым",
          value: true,
        },
      ],
    },
    {
      name: "certificateTemplateId",
      editor: "enum",
      attrs: {
        type: "text",
        outlined: true,
        label: "Выберите шаблон сертификата",
      },
      config: {
        getItems: "getTemplates",
        valueKeyName: "id",
        labelKeyName: "caption",
        type: "select",
        noDataText: "Данные не найдены",
      },
      validations: [
        {
          validator: "required",
          errorMessage: "Поле не может быть пустым",
          value: true,
        },
      ],
    },
  ];

  icons: any = {
    plus: mdiPlus,
    trash: mdiTrashCan,
  };
  $message: any;
  $confirm: any;

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "События",
        to: "/manage/events/",
        exact: true,
      },
      {
        text: this.eventObjectDataSource.model?.caption,
        to: `/manage/events/${this.$route.params.eventId}`,
        exact: true,
      },
      {
        text: "Сертификаты",
        disabled: true,
      },
    ];
  }
  async created() {
    await Promise.all([
      this.eventObjectDataSource.get(),
      this.dataSource.get(),
      this.personDataSource.get(),
      this.certificateTemplateDataSource.get(),
    ]);
    this.loaded = true;
  }

  async add() {
    const model = await this.dataEditorDialog.update(this.model);
    if (!model) return;
    try {
      await this.dataSource.add(model);
      this.$message("Успешно добавлено");
    } catch (error: any) {
      this.$message(error?.response?.data);
    }
  }

  async remove(item) {
    const confirm = await this.$confirm(
      "Вы действительно хотите удалить данный сертификат?"
    );
    if (!confirm) return;
    try {
      await this.dataSource.remove(item.id);
      this.$message("Успешно удалён");
    } catch (error: any) {
      this.$message(error?.response?.data);
    }
  }
}
