import { getFileBase64 } from "../../cms-services/helpers";
import axios from "axios";
import { ObjectDataSource } from "./ObjecDatatSource";

export class EventCertificateObjectDataSource extends ObjectDataSource {
  private _signature: any;
  private _stamp: any;

  constructor(id: number, model?: any, config?: any) {
    super({
      className: "EventCertificate",
      id,
      model,
      config,
    });
  }

  public get signature() {
    return getFileBase64(this._signature);
  }

  public get stamp() {
    return getFileBase64(this._stamp);
  }

  public async uploadSignature(event) {
    try {
      const { data } = await axios.post(
        `${this.baseUrl}/signature`,
        event.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
          },
          onUploadProgress(e) {
            event.onProgress(e, event.file);
          },
        },
      );
      this._signature = data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }

  public async uploadListenersCsv(event) {
    try {
      const { data } = await axios.post(
        `${this.baseUrl}/listeners`,
        event.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
          },
          onUploadProgress(e) {
            event.onProgress(e, event.file);
          },
        },
      );
      this.model.persons = [...this.model.persons, ...data];
    } catch (error: any) {
      throw error?.response?.data;
    }
  }

  public async uploadStamp(event: any) {
    try {
      const { data } = await axios.post(`${this.baseUrl}/stamp`, event.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        onUploadProgress(e) {
          event.onProgress(e, event.file);
        },
      });
      this._stamp = data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }

  public async loadSignature() {
    try {
      const { data } = await axios.get(`${this.baseUrl}/signature`);
      this._signature = data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }

  public async loadStamp() {
    try {
      const { data } = await axios.get(`${this.baseUrl}/stamp`);
      this._stamp = data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }
}
